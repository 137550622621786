

export default function Foot(){
    return(
        <div>
<section class="">
    <div class="max-w-screen-xl px-4 py-0 mx-auto space-y-2 overflow-hidden sm:px-6 lg:px-8">
        
        <div class="flex justify-center mt-8 space-x-6">
            
            
          
            
        </div>
        <p class="mt-0 text-base leading-6 text-center text-gray-400">
        Mirror Mirror Unisex Salon, UB6 8PU
        </p>
        
        <p class="mt-0 text-base leading-6 text-center text-gray-400">
            © 2024 Rigoleta Aesthetics, Inc. All rights reserved.
        </p>
    </div>
</section>


</div>
    )
}